dmx.Attribute('bind', 'mounted', function(node, attr) {
    var name = attr.argument;
    var toggle = dmx.reToggleAttribute.test(name);

    this.$addBinding(attr.value, function(value) {
        if (toggle) {
            //node[name] = !!value;
            if (!!value) {
                node.setAttribute(name, '');
            } else {
                node.removeAttribute(name);
            }
        } else {
            if (name === 'style' && typeof value === 'object') {
                for (var prop in value) {
                    node.style[prop] = value[prop];
                }
                return;
            }

            if (value != null) {
                node.setAttribute(name, value);
            } else {
                node.removeAttribute(name);
            }

            if (name === 'src') {
                if (node.nodeName === 'VIDEO' || node.nodeName === 'AUDIO') {
                    node.load();
                } else if (node.nodeName === 'SOURCE' && node.parentNode) {
                    node.parentNode.load();
                }
            }
        }
    });
});
